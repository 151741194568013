// export const chatMessengerInit = user => {
//   if (window.fcWidget) {
//     window.fcWidget.init({
//       token: 'baa8c17c-2e44-407a-a0a7-de97521cfced',
//       host: 'https://wchat.freshchat.com'
//     })

//     // window.fcWidget.user.setProperties({
//     //   firstName: user.name.split(' ')[0],
//     //   lastName: user.name.split(' ')[1],
//     //   email: user.email
//     // })
//   }
// }

export const chatMessengerDestroy = () => {
  // if (window.fcWidget) {
  //   window.fcWidget.destroy()
  // }

  if (window.$crisp?.do) {
    window.$crisp.do('chat:hide')
  }

  if (window.Intercom) {
    window.Intercom('shutdown')
  }

  if (window.LC_API?.hide_chat_window && window.LC_API?.close_chat) {
    window.LC_API.hide_chat_window()
    window.LC_API.close_chat()
  }
}

export const hideChatMessenger = () => {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    })
  }
  // if (window.fcWidget) {
  //   window.fcWidget.hide()
  // }

  if (window.$crisp?.do) {
    window.$crisp.do('chat:hide')
  }

  if (window.LC_API?.hide_chat_window) {
    window.LC_API.hide_chat_window()
  }
}

export const showChatMessenger = () => {
  // if (window.fcWidget) {
  //   window.fcWidget.show()
  // }

  if (window.$crisp?.do) {
    window.$crisp.do('chat:show')
  }

  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: false
    })
  }
  if (window.LC_API?.minimize_chat_window) {
    window.LC_API.minimize_chat_window()
  }
}

export const openChatMessengerWithMessage = (message = '') => {
  if (window.$crisp?.do) {
    window.$crisp.do('chat:show')
  }

  // if (window.fcWidget) {
  //   window.fcWidget.open({
  //     name: 'Support',
  //     replyText: message
  //   })
  // }
}

// export const postEvent = (eventName, metaData) => {
//   if (window.Intercom) {
//     window.Intercom('trackEvent', eventName, metaData)
//   }
// }

// ============= Intercom Connectivity ================
export const intercomConnect = (intercomId, meData) => {
  const establishConnection = () => {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomId,
      name: meData.name, // Full name
      email: meData.email, // Email address
      created_at: meData.createdAt
    })
  }

  if (window.Intercom) {
    establishConnection()
  } else {
    // Create a new script element
    const scriptElement = document.createElement('script')

    // Set the script's content to the provided JavaScript code
    const scriptCode = `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      `

    scriptElement.innerHTML = scriptCode

    // Append the script element to the <head> of your HTML document
    document.head.appendChild(scriptElement)

    // ========= Loop for 10 times (if window.Intercom not found, try again) =========
    let totalTrials = 10

    let intervalId = window.setInterval(() => {
      if (window.Intercom) {
        establishConnection()
        clearInterval(intervalId)
        return
      }

      if (totalTrials === 0) clearInterval(intervalId)
      totalTrials--
    }, 2000)
  }
}

// ============= Crisp Connectivity ================
export const crispConnect = crispId => {
  // if crisp already added then just return
  if (window.$crisp) return

  window.CRISP_WEBSITE_ID = crispId
  window.$crisp = []
  ;(function () {
    const d = document
    const s = d.createElement('script')
    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

// ============= Dashly Connectivity ================
export const dashlyConnect = dahslyId => {
  window.dashly.connect(dahslyId)
}

// ============= Facebook Pixel Connectivity ================
export const facebookPixelConnect = facebookPixelId => {
  ;(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  window.fbq('init', facebookPixelId)
  window.fbq('track', 'PageView')
}

// ============= Live chat Connectivity ================
export const liveChatConnect = license => {
  if (window.__lc) {
    console.warn('LiveChat is already loaded.')
    return
  }

  if (!license) {
    console.error('License key is required.')
    return
  }

  const scriptContent = `
    window.__lc = window.__lc || {};
    window.__lc.license = ${license};
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`

  const scriptElement = document.createElement('script')
  scriptElement.textContent = scriptContent
  document.body.appendChild(scriptElement)
}
