import React, { useEffect, useImperativeHandle, useState } from 'react'
import { TextField } from '@material-ui/core'
import AdvanceGeneralSettings from 'components/Settings/General/AdvanceGeneralSettings'
import { CustomTooltip } from 'global/globalComponents'
import { useSelector } from 'react-redux'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { userRoles } from 'utils'
import { useTranslation } from 'react-i18next'

const InvoiceOtherInfo = React.forwardRef(
  ({ invoiceData, isEditable }, ref) => {
    const { t } = useTranslation()
    const me = useSelector(state => state.me.data)
    const [taxDetails, setTaxDetails] = useState([])
    const [bankDetails, setBankDetails] = useState([])
    const [editAgencyInfo, setEditAgencyInfo] = useState(false)
    const [notes, setNotes] = useState(invoiceData.notes)

    useEffect(() => {
      // 1. Tax Details
      if (invoiceData.taxDetail) {
        if (typeof invoiceData.taxDetail[0] === 'object') {
          const newTaxDetail = invoiceData.taxDetail
            .map(item => `${item.label} - ${item.value}`)
            .join('\n')
          setTaxDetails([newTaxDetail])
        } else {
          setTaxDetails(invoiceData.taxDetail)
        }
      }

      // 2. Bank Details
      if (invoiceData.bankDetail) {
        if (typeof invoiceData.bankDetail[0] === 'object') {
          const newBankDetail = invoiceData.bankDetail
            .map(item => `${item.label} - ${item.value}`)
            .join('\n')
          setBankDetails([newBankDetail])
        } else {
          setBankDetails(invoiceData.bankDetail)
        }
      }
    }, [invoiceData])

    useImperativeHandle(ref, () => ({
      getData: () => {
        return { taxDetails, bankDetails, notes }
      }
    }))

    const updateTaxDetail = val => {
      // Right now it is just strings inside an array, in future it may become array of objects
      setTaxDetails([val])
    }

    const updateBankDetail = val => {
      // Right now it is just strings inside an array, in future it may become array of objects
      setBankDetails([val])
    }

    return (
      <div>
        <div className="mb-6 sm:w-1/2">
          <label className="flex items-center text-gray-500 uppercase tracking-wider text-xs font-medium mb-1">
            {t('TAX_INFO')}
            {isEditable && (
              <>
                {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                  me.role
                ) && (
                  <span>
                    <CustomTooltip
                      title={t('UPDATE_AGENCY_TAX_AND_BANK_DETAILS')}
                      placement="top"
                    >
                      <Pen
                        className=" ml-4 px-1 h-4 w-5 cursor-pointer border-b border-primary-main text-primary-main hover:bg-primary-light"
                        onClick={() => setEditAgencyInfo(true)}
                      />
                    </CustomTooltip>
                  </span>
                )}
              </>
            )}
          </label>
          {isEditable ? (
            <TextField
              value={taxDetails[0]}
              onChange={e => updateTaxDetail(e.target.value)}
              className="w-full"
              multiline={true}
            />
          ) : (
            <div className="text-smaller whitespace-pre-wrap">
              {taxDetails[0]}
            </div>
          )}
        </div>
        <div className="mb-6 sm:w-1/2">
          <label className="text-gray-500 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
            {t('BANK_DETAILS')}
          </label>

          {isEditable ? (
            <TextField
              value={bankDetails[0]}
              onChange={e => updateBankDetail(e.target.value)}
              className="w-full"
              multiline={true}
            />
          ) : (
            <div className="text-smaller whitespace-pre-wrap">
              {bankDetails[0]}
            </div>
          )}
        </div>
        <div className="mb-6">
          {(isEditable || Boolean(notes)) && (
            <label className="text-gray-500 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
              {t('NOTES')}
            </label>
          )}
          {isEditable ? (
            <TextField
              value={notes}
              onChange={e => setNotes(e.target.value)}
              placeholder={t('THANK_YOU_FOR_YOUR_BUSINESS')}
              className="w-full"
              multiline={true}
              rows={3}
            />
          ) : (
            <div className="text-smaller">{notes}</div>
          )}
        </div>
        <AdvanceGeneralSettings
          open={editAgencyInfo}
          handleClose={() => setEditAgencyInfo(false)}
          updateTaxDetail={updateTaxDetail}
          updateBankDetail={updateBankDetail}
        />
      </div>
    )
  }
)

export default InvoiceOtherInfo
