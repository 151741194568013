import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { hideChatMessenger, showChatMessenger } from 'utils'
import { useLocation } from 'react-router-dom'

const hideChatWidgetPaths = [
  '/agency/workspace',
  '/client/workspace',
  '/agency/chat-stream',
  '/client/chat-stream',
  '/agency/service-marketplace',
  '/agency/payments'
]

const useToggleChatWidget = () => {
  const { pathname } = useLocation()
  const { loggedIn } = useSelector(state => state.login)

  useEffect(() => {
    let timerId = window.setTimeout(() => {
      const match = hideChatWidgetPaths.find(path => pathname.includes(path))

      if (match || !loggedIn) hideChatMessenger()
      else showChatMessenger()
    }, 1000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [pathname, loggedIn])

  return null
}

export default useToggleChatWidget
