import userRoles from 'utils/userRoles'
import { ReactComponent as Home } from 'static/svg/home-filled.svg'
import { ReactComponent as Folder } from 'static/svg/folder-filled.svg'
import { ReactComponent as CommentTextIcon } from 'static/svg/comment-text-filled.svg'
import { ReactComponent as UsersIcon } from 'static/svg/users-filled.svg'
import { ReactComponent as ServiceIcon } from 'static/svg/flash-filled.svg'
import { ReactComponent as ClientIcon } from 'static/svg/user-circle-filled.svg'
import { ReactComponent as InvoicesIcon } from 'static/svg/invoice-filled.svg'
import { ReactComponent as Modules } from 'static/svg/modules.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting-filled.svg'
import { ReactComponent as RocketIcon } from 'static/svg/rocket.svg'
import { ReactComponent as StorageIcon } from 'static/svg/server-filled.svg'
// import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch-filled.svg'
import { dateAndTimeHelper } from 'utils'
import { t } from 'i18next'
import { getStorageAccessPermission } from 'components/Storage/storageStatic'

const checkCanAccess = permission => {
  for (let key in permission) {
    if (permission[key]) return true
  }

  return false
}

export const getSideNavItems = ({
  meRole,
  permission = {},
  basePath,
  unreadChatCount,
  accountCreatedAt
}) => {
  const storagePermission = getStorageAccessPermission(meRole)

  return [
    {
      text: t('DASHBOARD'),
      name: 'dashboard',
      link: '/dashboard',
      icon: <Home className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: basePath + '/dashboard',
      canAccess: checkCanAccess(permission.workspace)
    },
    {
      text: t('PROJECTS'),
      name: 'projects',
      link: '/workspace/all',
      icon: <Folder className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: basePath + '/workspace',
      canAccess: checkCanAccess(permission.workspace)
    },
    {
      text: t('CLIENTS'),
      name: 'clients',
      link: '/clients/all',
      activeNavString: '/agency/clients',
      icon: <ClientIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      canAccess: checkCanAccess(permission.clients),
      tabs: [
        {
          label: t('CONTACTS'),
          to: basePath + '/clients/all'
        },
        {
          label: t('ORGANIZATIONS'),
          to: basePath + '/clients/company'
        },
        {
          label: t('INACTIVE_CONTACTS'),
          to: basePath + '/clients/inactive'
        },
        ...(meRole !== userRoles.AGENCY_EDITOR &&
        meRole !== userRoles.AGENCY_VIEWER
          ? [
              {
                label: t('REQUESTS'),
                to: basePath + '/clients/requests'
              }
            ]
          : [])
      ]
    },
    {
      text: t('SERVICES'),
      name: 'services',
      link: '/services',
      icon: <ServiceIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/services',
      canAccess:
        meRole >= userRoles.USER_CLIENT
          ? checkCanAccess(permission.services)
          : null
    },
    {
      text: t('TEAM'),
      name: 'team',
      link: '/teammates/all',
      icon: <UsersIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: basePath + '/teammates',
      canAccess: checkCanAccess(permission.teammate),
      tabs:
        meRole < userRoles.USER_CLIENT
          ? [
              {
                label: t('TEAM_MEMBERS'),
                to: basePath + '/teammates/all'
              },
              {
                label: t('UNDER_APPROVAL'),
                to: basePath + '/teammates/under-approval'
              },
              ...(checkCanAccess(permission.timesheet)
                ? [
                    {
                      label: t('TIMESHEETS'),
                      to:
                        basePath +
                        `/teammates/timesheets/${new Date()
                          .toISOString()
                          .substring(0, 10)}`
                    },
                    {
                      label: [
                        userRoles.USER_AGENCY,
                        userRoles.AGENCY_ADMIN
                      ].includes(meRole)
                        ? t('RESOURCE_MANAGEMENT')
                        : t('MY_WORKLOAD'),
                      to: basePath + '/teammates/resource-management'
                      // icon: <PeopleIcon />,
                    }
                  ]
                : [])
            ]
          : null
    },
    {
      text: t('CHAT'),
      name: 'chat',
      link: '/chat-stream',
      icon: <CommentTextIcon />,
      activeNavString: basePath + '/chat-stream',
      canAccess: true, // for all
      badge: unreadChatCount
    },
    {
      text: t('WORKFLOWS'),
      name: 'workflows',
      link: '/workflows/templates',
      icon: <Modules className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/workflow',
      canAccess:
        meRole < userRoles.USER_CLIENT
          ? checkCanAccess(permission.onboarding)
          : null,
      tabs: [
        {
          label: t('TEMPLATES'),
          to: basePath + '/workflows/templates'
        },
        {
          label: t('CLIENT_RESPONSES'),
          to: basePath + '/workflows/client-responses'
        }
      ]
    },
    {
      text: t('PAYMENTS'),
      name: 'payments',
      link: checkCanAccess(permission.billings)
        ? '/payments/client-billing/all'
        : '/payments/services/all',
      icon: <InvoicesIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/payments',
      canAccess:
        meRole < userRoles.USER_CLIENT
          ? checkCanAccess(permission.billings) ||
            checkCanAccess(permission.services)
          : null,
      tabs: [
        ...(checkCanAccess(permission.billings)
          ? [
              {
                label: t('INVOICES'),
                to: basePath + '/payments/client-billing/all'
              }
            ]
          : []),

        ...(checkCanAccess(permission.services)
          ? [
              {
                label: t('SERVICES'),
                to: basePath + '/payments/services/all'
                // icon: <ServiceIcon />,
              }
            ]
          : []),
        ...(checkCanAccess(permission.billings)
          ? [
              {
                label: t('SUBSCRIPTIONS'),
                to: basePath + '/payments/subscriptions'
              }
            ]
          : []),
        ...([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meRole)
          ? [
              {
                label: t('COUPONS'),
                to: basePath + '/payments/coupons'
                // icon: <ServiceIcon />,
              }
            ]
          : [])
      ]
    },
    {
      text: t('INVOICES'),
      name: 'invoices',
      link: '/invoices',
      icon: <InvoicesIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/client/invoices',
      canAccess: meRole === userRoles.USER_CLIENT
    },
    // {
    //   text: t('WORKLOADS'),
    //   name: 'workload',
    //   link: `/workload/timesheets/${new Date().toISOString().substring(0, 10)}`,
    //   icon: (
    //     <StopWatchIcon className="w-4 h-4 2xl:w-5 2xl:h-5 dark:text-dark-light" />
    //   ),
    //   activeNavString: '/workload',
    //   canAccess: checkCanAccess(permission.timesheet),
    //   tabs: [
    //     {
    //       label: t('TIMESHEETS'),
    //       to:
    //         basePath +
    //         `/workload/timesheets/${new Date().toISOString().substring(0, 10)}`
    //       // icon: <StopWatchIcon className="dark:text-dark-light" />,
    //     },
    //     {
    //       label: [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
    //         meRole
    //       )
    //         ? t('RESOURCE_MANAGEMENT')
    //         : t('MY_WORKLOAD'),
    //       to: basePath + '/workload/resource-management'
    //       // icon: <PeopleIcon />,
    //     }
    //   ]
    // },
    // {
    //   text: t('APPROVALS'),
    //   name: 'approvals',
    //   link: '/approvals/raised',
    //   activeNavString: '/approvals',
    //   icon: (
    //     <CheckCircleFilledIcon className="w-4 h-4 2xl:w-5 2xl:h-5 dark:text-dark-light" />
    //   ),
    //   canAccess: !isMarketplaceDomain,
    //   tabs: [
    //     {
    //       label: t('RAISED'),
    //       to: `${basePath}/approvals/raised`
    //     },
    //     {
    //       label: t('RECEIVED'),
    //       to: `${basePath}/approvals/received`
    //     }
    //   ]
    // },

    // =========== TEMP STORAGE ===========
    {
      text: t('STORAGE'),
      name: 'storage',
      link: '/storage',
      icon: <StorageIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: basePath + '/storage',
      canAccess: meRole <= userRoles.USER_CLIENT,
      tabs: [
        {
          label: t('PERSONAL'),
          to: basePath + '/storage/personal-storage'
        },
        ...(storagePermission.teamStorage
          ? [
              {
                label: t('TEAM'),
                to: basePath + '/storage/team-storage'
              }
            ]
          : []),
        ...(storagePermission.clientStorage
          ? [
              {
                label: t('CLIENTS'),
                to: basePath + '/storage/client-storage'
              }
            ]
          : []),
        ...(storagePermission.sharedWithMe
          ? [
              {
                label: t('SHARED_WITH_ME'),
                to: basePath + '/storage/shared-me'
              }
            ]
          : []),
        ...(storagePermission.projectStorage
          ? [
              {
                label: t('PROJECTS'),
                to: basePath + '/storage/project-storage'
              }
            ]
          : [])
      ]
    },
    {
      text: t('SETTINGS'),
      name: 'settings',
      link: ![userRoles.USER_AGENCY].includes(meRole)
        ? '/settings/profile'
        : '/settings/branding',
      icon: <SettingIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/settings',
      canAccess: true,
      tabs: [
        ...(meRole === userRoles.USER_AGENCY
          ? [
              {
                label: t('BRANDING_N_WHITELABEL'),
                to: basePath + '/settings/branding'
              }
            ]
          : []),
        {
          label: t('PROFILE'),
          to: basePath + '/settings/profile'
        },
        ...(meRole === userRoles.USER_CLIENT
          ? [
              {
                label: t('ORGANIZATION_INFORMATION'),
                to: basePath + '/settings/company-information'
              }
            ]
          : []),
        ...(meRole <= userRoles.AGENCY_ADMIN
          ? [
              {
                label: t('INTEGRATION'),
                to: basePath + '/settings/integrations'
              }
            ]
          : []),
        {
          label: t('SECURITY'),
          to: basePath + '/settings/security'
        },
        ...(meRole === userRoles.USER_AGENCY
          ? [
              {
                label: t('PLANS'),
                to: basePath + '/settings/subscription'
              }
            ]
          : []),
        ...([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meRole)
          ? [
              {
                label: t('RESOURCE_MANAGEMENT'),
                to: basePath + '/settings/resource-manage'
              },
              {
                label: t('IMPORT'),
                to: basePath + '/settings/import'
              }
            ]
          : []),
        {
          label: t('NOTIFICATION'),
          to: basePath + '/settings/notification'
        },
        ...(meRole === userRoles.USER_AGENCY
          ? [
              {
                label: t('PERMISSION'),
                to: basePath + '/settings/permissions'
              }
            ]
          : []),
        ...([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meRole)
          ? [
              {
                label: t('TEMPLATES'),
                to: basePath + '/settings/templates'
              }
            ]
          : [])
      ]
    },
    {
      text: t('GETTING_STARTED'),
      name: 'gettingStarted',
      link: '/getting-started',
      icon: <RocketIcon className="w-4 h-4 2xl:w-5 2xl:h-5" />,
      activeNavString: '/getting-started',
      canAccess:
        dateAndTimeHelper.getDateDiffInDays(accountCreatedAt) <= 7 &&
        meRole > userRoles.USER_CLIENT
          ? permission?.onboarding
          : null
    }
  ].filter(item => item.canAccess)
}
