import { Modal, makeStyles, Backdrop, Fade } from '@material-ui/core'
import { useEffect } from 'react'
import { hideChatMessenger } from 'utils'
// import clsx from 'clsx'

// Modal Size options => small, medium (default), large

const paperSizes = {
  extraSmall: '450px',
  small: '550px',
  medium: '650px',
  large: '800px',
  extraLarge: '900px',
  doublexl: '1300px'
}

const CustomModal = ({
  open,
  handleClose,
  children,
  size = 'medium',
  overflow = 'hidden',
  style = {},
  backdropInvisible = false,
  rounded = '2xl',
  ...rest
}) => {
  const classes = useStyles({ size })

  // ======= Hiding chat widget on modal open ====
  // Note: Not showing it back on modal close bcoz if we are in a section where we don't want
  // chat widget (ex: board) and there if we open and modal and close it the chat widget would
  // show up, to prevent that behavior, not showing it back
  useEffect(() => {
    if (open) hideChatMessenger()
  }, [open])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        invisible: backdropInvisible
      }}
      {...rest}
      className={classes.modal}
    >
      <Fade in={open}>
        <div
          className={`relative rounded-${rounded} ${classes.paper} overflow-${overflow} dark:bg-dark-main2`}
          style={style}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
    // userSelect: 'none'
  },
  paper: props => ({
    width: '90%',
    maxWidth: paperSizes[props.size] || props.size,
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none'
    // borderRadius: '1.5rem',
  }),
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  }
}))

export default CustomModal
