// import { data } from 'autoprefixer'

export const DASHBOARD = {
  LOADING: 'DASHBOARD_CARD_DATA_LOADING',
  FETCHED: 'FETCHED_DASHBOARD_CARD_DATA',
  UPDATE: 'UPDATE_DASHBOARD_CARD_DATA',
  TIMER: 'DASHBOARD_TASK_TIMER',
  TIMER_UPDATE: 'DASHBOARD_TASK_TIMER_UPDATE',
  ERROR: 'ERROR'
}

const initialState = {
  loading: false,
  error: false,
  data: {},
  timer: [],
  fetched: false,
  fetchedTimer: false
}

const userDashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.LOADING:
      return { ...state, loading: true, error: false }

    case DASHBOARD.ERROR:
      return { ...state, loading: false, error: action.payload }

    case DASHBOARD.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        fetched: true
      }

    case DASHBOARD.TIMER:
      return {
        ...state,
        timer: action.payload,
        fetchedTimer: true
      }

    case DASHBOARD.TIMER_UPDATE:
      return {
        ...state,
        timer: action.payload
      }

    case DASHBOARD.UPDATE:
      let newData = { ...state.data }
      if (
        action.payload.key === 'totalRevenue' &&
        action.payload.count.amount &&
        action.payload.count.currency
      ) {
        const {
          count: { amount, currency },
        
        } = action.payload
        if (currency in state.data.totalRevenue) {
          newData = {
            ...state.data,
            totalRevenue: {
              ...state.data.totalRevenue,
              [currency]: state.data.totalRevenue[currency] + amount
            }
          }
        } else {
          newData = {
            ...state.data,
            totalRevenue: { ...state.data.totalRevenue, [currency]: amount }
          }
        }
      } else if (
        action.payload.key === 'totalServices' ||
        action.payload.key === 'totalClients'
      )
        newData = {
          ...state.data,
          [action.payload.key]:
            state.data[action.payload.key] + action.payload.count
        }
      else newData = { ...state.data }
      return {
        ...state,
        loading: false,
        data: newData,
        fetched: true
      }

    default:
      return state
  }
}

export default userDashboard
