import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  useTheme
} from '@material-ui/core'
import Select from 'react-select'
import userRoles from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import Category from 'utils/workspaceCategories'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import clientStyles from './ClientStyles'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
import { useTranslation } from 'react-i18next'
import { addClient } from 'thunks/addClient/actions'
import { gettingAllTasksStatus } from 'thunks/gettingStarted/actions'
import Countries from 'static/countries/Countries'
import { validator } from 'utils'

const AddNewClient = ({ open, onClose, data, styles, callback }) => {
  const { t } = useTranslation()

  return (
    <CustomDrawer
      id="addNewClientDrawer"
      onClose={onClose}
      anchor="right"
      open={open}
      styles={styles}
    >
      <DrawerHeader onClose={onClose}>{t('ADD_NEW_CLIENT')}</DrawerHeader>

      <PanelContent
        onClose={onClose}
        data={data}
        styles={styles}
        t={t}
        callback={callback}
      />
    </CustomDrawer>
  )
}

const PanelContent = ({ onClose, data, t, callback }) => {
  const theme = useTheme()
  const classes = clientStyles()
  const dispatch = useDispatch()
  const [passwordValid, setPasswordValid] = useState(true)
  const [agencyRepresentative, setAgencyRepresentative] = useState([])
  const teammate = useSelector(state => state.userTeammates.data)
  const userWorkspacesData = useSelector(state => state.userWorkspaces.data)
  const meData = useSelector(state => state.me.data)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [workspaceOptions, setWorkspaceOptions] = useState([])
  const [toggleSwitch, setToggleSwitch] = useState({
    checked: true,
    disable: false,
    load: false
  })
  const { companyInfo } = useSelector(state => state.generalAppState)
  const [loading, setLoading] = useState(false)

  const [clientData, setClientData] = useState({
    name: '',
    role: userRoles.USER_CLIENT,
    email: '',
    phoneNumber: '',
    password: '',
    // address: {
    //   line1: '',
    //   line2: '',
    //   city: '',
    //   state: '',
    //   zipCode: '',
    //   country: ''
    // },
    agencyRepresentative: companyInfo.data._id,
    newWorkspace: [],
    quota: {
      project: {
        total: ''
      },
      request: {
        total: ''
      }
    }
  })

  useEffect(() => {
    if (userWorkspacesData.length) {
      const activeProjects = userWorkspacesData.filter(
        item =>
          item.type === Category.EXTERNAL &&
          !item.isArchived &&
          !item.isComplete &&
          item.assignedClientTeam.length === 0
      )
      setWorkspaceOptions(activeProjects)
    }
  }, [])

  useEffect(() => {
    if (data) {
      setClientData(prev => ({
        ...prev,
        email: data?.email || '',
        newWorkspace: data?.assignedWorkspace ?? []
      }))
    }
  }, [data])

  // const country = []
  const formInputHandler = e => {
    const { value, name } = e.target
    if (name === 'password') {
      setPasswordValid(true)
    }
    if (name === 'phoneNumber' && value.length > 10) {
      return
    } else {
      setClientData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  const handleSelectWorkspace = e => {
    setClientData(oldState => ({
      ...oldState,
      newWorkspace: e
    }))
  }

  const handleButtonClick = () => {
    // ------- Checking if required fields are filled --------
    if (!clientData.name.trim() || !clientData.email.trim()) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_FILL_THE_REQUIRED_FIELDS'))
      )
    }

    if (!validEmailAddress()) {
      return dispatch(
        fireErrorToaster(t('ERROR_PLEASE_ENTER_YOUR_EMAIL_ADDRESS'))
      )
    }

    if (!validator.isPasswordValid(clientData.password)) {
      setPasswordValid(false)
      return
    }

    if (!clientData.quota.request.total || clientData.quota.request.total < 0) {
      return dispatch(fireErrorToaster(t('REQUEST_QUOTA_UPDATE_WARNING')))
    }

    clientData.newWorkspace = clientData.newWorkspace.map(item => item._id)
    // -------- Form Submission -----------

    const data = {
      ...clientData,
      canAddTeammates: true,
      agencyRepresentative: clientData.agencyRepresentative
        ? clientData.agencyRepresentative
        : companyInfo.data._id,
      language: companyInfo.data.platformLanguage || 'en' //default english
    }

    if (toggleSwitch.checked) {
      data.path = '/client/dashboard'
    }

    setLoading(true)
    dispatch(
      addClient({ body: data }, (res, err) => {
        dispatch(gettingAllTasksStatus())
        addClientCallback(res, err)

        if (!err && callback) {
          callback(res)
        }
      })
    )
    toggleClientEmailModal()
  }

  const addClientCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster(t('CLIENT_ADDED_SUCCESSFULLY')))
      onClose()
    } else {
      console.log({ res, err })
      dispatch(fireErrorToaster(res))
    }
  }

  const toggleClientEmailModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false)
    } else {
      setIsModalOpen(true)
    }
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(clientData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster(t('ERROR_PLEASE_ENTER_VALID_EMAIL_ADDRESS')))
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    let arr = []

    if (
      [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      arr.push({ value: meData._id, label: meData.name })
    }

    teammate
      .filter(mate => mate.role !== userRoles.AGENCY_VIEWER)
      .forEach(item => {
        arr.push({ value: item._id, label: item.name })
      })
    setAgencyRepresentative(arr)
  }, [teammate])

  const handleAgencyRepresentativeChange = e => {
    setClientData(prevState => ({
      ...prevState,
      agencyRepresentative: e.value
    }))
  }

  // const closeSidePanel = () => {
  //   onClose()
  //   setClientData({
  //     name: '',
  //     role: userRoles.USER_CLIENT,
  //     email: '',
  //     phoneNumber: '',
  //     password: '',
  //     address: {
  //       country: ''
  //     },
  //     agencyRepresentative: null,
  //     newWorkspace: [],
  //     quota: {
  //       project: { total: '' },
  //       request: { total: '' }
  //     }
  //   })

  //   setToggleSwitch(prev => ({
  //     ...prev,
  //     checked: true,
  //     disable: false,
  //     load: false
  //   }))
  // }

  const handleSwitch = () => {
    setToggleSwitch(prev => ({
      ...prev,
      checked: !toggleSwitch.checked
    }))
  }

  const togglePasswordView = () => {
    setShowPassword(prev => !prev)
  }

  const handleUpdateQuota = e => {
    let value = parseInt(e.target.value) || ''

    if (value > 9999) value = 9999

    setClientData(prev => ({
      ...prev,
      quota: {
        ...prev.quota,
        [e.target.name]: {
          total: value
        }
      }
    }))
  }

  // const handleAddressChange = e => {
  //   setClientData(prev => ({
  //     ...prev,
  //     address: { ...prev.address, [e.target.name]: e.target.value }
  //   }))
  // }

  return (
    <>
      <DrawerContent open={true}>
        <div className="mb-4 mt-2">
          <FormLabel htmlFor="clientName" required>
            {t('FULL_NAME')}
          </FormLabel>
          <TextField
            id="clientName"
            className={classes.textFieldStyle}
            name="name"
            value={clientData.name}
            type="text"
            placeholder={t('FULL_NAME')}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="clientEmail" required>
            {t('EMAIL')}
          </FormLabel>
          <TextField
            id="clientEmail"
            className={classes.textFieldStyle}
            inputProps={{
              autoComplete: 'off'
            }}
            name="email"
            type="email"
            placeholder={t('EMAIL')}
            value={clientData.email}
            onChange={formInputHandler}
            onBlur={validEmailAddress}
          />
        </div>

        {/* <div className="mb-4">
          <FormLabel required>{t('ADDRESS')}</FormLabel>
          <div>
            <div className="mb-2">
              <TextField
                required
                placeholder="Line 1"
                name="line1"
                className="w-full"
                value={clientData.address.line1}
                onChange={handleAddressChange}
              />
            </div>
            <div className="mb-2">
              <TextField
                required
                placeholder="Line 2 (Optional)"
                name="line2"
                value={clientData.address.line2}
                className="w-full"
                onChange={handleAddressChange}
              />
            </div>

            <div className="flex mb-2 space-x-2">
              <div className="flex-1">
                <TextField
                  required
                  placeholder="City"
                  value={clientData.address.city}
                  name="city"
                  onChange={handleAddressChange}
                  fullWidth
                />
              </div>
              <div className="flex-1">
                <TextField
                  required
                  value={clientData.address.state}
                  name="state"
                  placeholder="State"
                  onChange={handleAddressChange}
                  fullWidth
                />
              </div>
            </div>

            <div className="flex space-x-2">
              <div className="flex-1">
                <Select
                  isSearchable={true}
                  placeholder="country"
                  options={Countries}
                  maxMenuHeight={120}
                  value={Countries.find(
                    item => item.value === clientData.address.country
                  )}
                  onChange={newVal =>
                    setClientData(prev => ({
                      ...prev,
                      address: {
                        ...prev.address,
                        country: newVal.value
                      }
                    }))
                  }
                  styles={reactSelectCustomStyles(theme)}
                  className="w-full"
                />
              </div>

              <div className="flex-1">
                <TextField
                  required
                  placeholder="Zip Code"
                  name="zipCode"
                  value={clientData.address.zipCode}
                  onChange={handleAddressChange}
                  type="text"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="mb-4">
          <FormLabel htmlFor="clientPhoneNumber">{t('PHONE_NUMBER')}</FormLabel>
          <TextField
            id="clientPhoneNumber"
            className={classes.textFieldStyle}
            name="phoneNumber"
            type="number"
            placeholder={t('PHONE_NUMBER')}
            value={clientData.phoneNumber}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('AGENCY_REPRESENTATIVE')}</FormLabel>
          <Select
            id="clientAgencyRepresentative"
            className="col-span-4 md:col-span-2 text-sm"
            placeholder={t('ASSIGN_AGENCY_REPRESENTATIVE')}
            isSearchable={false}
            options={agencyRepresentative}
            //  value={formData.address.country}
            onChange={handleAgencyRepresentativeChange}
            // styles={customStyles}
            styles={reactSelectCustomStyles(theme)}
          />
        </div>

        <div className="mb-4">
          <FormLabel
            htmlFor="clientAssignProject"
            className="flex items-center gap-1"
          >
            {t('ASSIGN_PROJECT')}
          </FormLabel>
          <Select
            id="clientAssignProject"
            isSearchable={true}
            onChange={handleSelectWorkspace}
            isMulti
            value={clientData.newWorkspace}
            options={workspaceOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            className={classes.selectField}
            styles={reactSelectCustomStyles(theme)}
            name="projects"
            maxMenuHeight={150}
            menuPlacement="top"
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="ticketQuota" className="capitalize" required>
            {t('REQUEST_QUOTA')}
          </FormLabel>
          <TextField
            id="ticketQuota"
            type="number"
            value={clientData.quota.request.total}
            onChange={handleUpdateQuota}
            name="request"
            placeholder={0}
            fullWidth
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="projectQuota" className="capitalize">
            {t('PROJECT_CREATION_QUOTA')}
          </FormLabel>
          <TextField
            id="projectQuota"
            type="number"
            value={clientData.quota.project.total}
            onChange={handleUpdateQuota}
            name="project"
            placeholder={0}
            fullWidth
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="clientPassword">{t('PASSWORD')}</FormLabel>
          <TextField
            className={classes.textFieldStyle}
            id="clientPassword"
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder={t('PASSWORD')}
            value={clientData.password}
            onChange={formInputHandler}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={togglePasswordView}>
                    {!showPassword ? (
                      <EyeIcon className="text-base" />
                    ) : (
                      <EyeSlashIcon className="text-base" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className="text-xs mt-1 dark:text-dark-light">
            {t('PASSWORD_WILL_BE_AUTO_GENERATED')}
          </p>
          {clientData.password && !passwordValid && (
            <p className="text-xs mt-1" style={{ color: '#f44336' }}>
              {t('PASSWORD_WARNING_TEXT')}
            </p>
          )}
        </div>

        <div className="flex items-center justify-between mb-4">
          <p className="text-sm font-medium text-gray-700 dark:text-dark-light">
            {t('SKIP_CHECKOUT')}
          </p>

          <div className="flex items-center gap-1">
            <span className="text-sm font-medium text-gray-700 dark:text-dark-light">
              {t('NO')}
            </span>
            <ToggleSwitch
              checked={toggleSwitch.checked}
              onChange={handleSwitch}
              disableRipple={true}
              id="skipCheckoutBtn"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-dark-light">
              {t('YES')}
            </span>
          </div>
        </div>
      </DrawerContent>

      <DrawerFooter className={classes.panelFooter}>
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
          className="w-full"
          id="addClientCancelBtn"
        >
          {t('CANCEL')}
        </Button>

        <LoadingButton
          size="large"
          loading={loading}
          onClick={handleButtonClick}
          className="w-full dark:bg-dark-main"
          id="addClientInviteBtn"
        >
          {t('INVITE')}
        </LoadingButton>
      </DrawerFooter>
    </>
  )
}

export default React.memo(AddNewClient)
