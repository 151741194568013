import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  moveTaskToTrash,
  updateTaskDetails,
  deleteAttachment
} from 'thunks/task/actions'
import { findCommentsByTaskId, removeLogs } from 'thunks/logs/action'
import { clearSubtasksData, getAllSubTask } from 'thunks/subTask/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import clsx from 'clsx'
import SubtaskPanel from './SubtaskPanel'
// import { DrawerContent } from 'global/globalComponents/CustomDrawer/CustomDrawer'
import taskSidePanelStyles from './taskSidePanelStyles'
import LogsSection from './LogsSection/LogsSection'
import TaskPanelHeader from './TaskPanelHeader'
import TaskPanelFooter from './TaskPanelFooter'
import SingleTaskPusherWrapper from 'components/Pusher/SingleTaskPusherWrapper'
import Subtasks from './Subtasks'
import TaskBody from './TaskBody'
import TaskTitle from './TaskTitle'
import TaskDescription from './TaskDescription'
import CollabDrowdown from './CollabDrowdown'
import AddAttachment from './AddAttachment'
import { CircularProgress } from '@material-ui/core'
// import RecurringDropDown from '../RecurringDropDown'
import Tags from 'components/UserDashboard/Workspaces/Tags'
import AttachmentImage from './AttachmentImage'
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation
} from 'react-router-dom'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import {
  openApprovalModal,
  showHeaderShadow
} from 'thunks/generlAppState/actions'
import { Drawer } from '@material-ui/core'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import TaskPanelLoading from './TaskPanelLoading'
// import ApprovalPanel from './Approval/ApprovalPanel'
// import { fetchTaskApprovals } from 'thunks/approvals/actions'
import { useWorkspacePermission } from 'customHooks'
// import AutoSavingIndicator from './AutoSavingIndicator'
import { userRoles } from 'utils'
import { fetchSingleEmbedById } from 'thunks/embed/action'
import { useTranslation } from 'react-i18next'
import { AutoSavingIndicator } from 'global/globalComponents'

export default function TaskPanel({
  open,
  onClose,
  taskData,
  inDashboardArea = false,
  isLoading,
  ...rest
}) {
  const { payNowStripe } = useSelector(state => state.generalAppState)
  const classes = taskSidePanelStyles({
    inDashboardArea,
    isPayNowStripeVisible: payNowStripe.isVisible
  })

  return (
    <SingleTaskPusherWrapper taskId={taskData._id} connect={open}>
      <Drawer
        onClose={onClose}
        classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
        anchor="right"
        open={open}
        transitionDuration={400}
      >
        {/* ============== HEADER =============== */}
        {isLoading || !taskData._id ? (
          <TaskPanelLoading />
        ) : (
          <TaskPanelContents
            open={open}
            onClose={onClose}
            taskData={taskData}
            inDashboardArea={inDashboardArea}
            classes={classes}
            {...rest}
          />
        )}
      </Drawer>
    </SingleTaskPusherWrapper>
  )
}

const TaskPanelContents = ({
  open,
  onClose,
  taskData,
  // groupBy = 'status',
  isOutsideBoardArea,
  type,
  updateProjectStats,
  inDashboardArea,
  classes,
  workspace,
  board,
  displayProjectField
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [boardData, setBoardData] = useState(board ?? {})
  const innerProjectPermission = useWorkspacePermission(taskData.workSpace)
  const { search, hash } = useLocation()

  const logsBottomRef = useRef(null)

  const meData = useSelector(state => state.me.data)
  const taskTags = useSelector(state => state.userTags.currentWorkspaceTags)
  const [openSubTask, setOpenSubTask] = useState({ open: false, data: {} })
  const [collabOptions, setCollabOptions] = useState([])
  const [assignedTags, setAssignedTags] = useState([])

  const [taskState, setTaskState] = useState({
    title: '',
    workSpace: '',
    taskSection: {},
    createdAt: `${new Date()}`,
    startDate: '',
    dueDate: '',
    description: '',
    assignedAgencyTeam: [],
    assignedClientTeam: [],
    imageUrl: [],
    comment: '',
    status: null,
    user: {},
    approval: {},
    estimateTimeInMillisecond: '',
    estimateTimeInString: ''
  })

  const [loadingAttachment, setLoadingAttachment] = useState(false)
  // const [deleteTaskLoading, setDeleteTaskLoading] = useState(false)
  // const [imgPreview, setImgPreview] = useState({
  //   open: false,
  //   url: ''
  // })

  const [tagsPopoverData, setTagsPopoverData] = useState({
    open: false,
    anchorEl: null
  })
  const [selectedTab, setSelectedTab] = useState(0)
  // const [boardArea, setBoardArea] = useState(false)
  const allSubtasks = useSelector(state => state.subTasks.data)
  const { subtaskId } = useParams()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { socketId } = useContext(WorkspacePusherContext)
  // const [approvalPanel, setApprovalPanel] = useState({
  //   open: false,
  //   loading: true,
  //   data: []
  // })
  const [savingStatus, setSavingStatus] = useState('') // statuses: "saving", "saved", "failed"
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popperOpen, setPopperOpen] = useState('')
  const taskDescriptionRef = useRef()
  const [scrolled, setScrolled] = useState(false)
  // const [sliderArr, setSliderArr] = useState([])
  // const [selectedFile, setSelectedFile] = useState(null)
  // const [openSlider, setOpenSlider] = useState(false)

  const {
    getCollaboratorsOptions,
    handleTaskDateChange,
    canUpdateTask,
    addTaskCollaborator,
    removeTaskCollaborator,
    // changeTaskSection,
    // changeTaskPriority,
    // markTaskAsComplete,
    uploadTaskAttachment,
    checkAndGetNewFileName
  } = useWorkspaceFunctions()
  const [alertModal, setAlertModal] = useState({
    type: '',
    loading: false,
    data: {}
  })

  useEffect(() => {
    return () => {
      dispatch(removeLogs('task'))
      dispatch(clearSubtasksData())
    }
  }, [])

  useEffect(() => {
    dispatch(showHeaderShadow(!open))
  }, [open])

  useEffect(() => {
    if (!boardData._id) {
      fetchSingleEmbedById({ id: taskData.workspaceBoard }, (res, err) => {
        if (!err) setBoardData(res)
      })
    }
  }, [boardData._id, taskData.workspaceBoard])

  useEffect(() => {
    if (taskData._id) {
      setTaskState({
        estimateTimeInString: '', // old tasks may not have this prop
        estimateTimeInMillisecond: '', // old tasks may not have this prop
        ...taskData
      })
    }
  }, [taskData])

  useEffect(() => {
    if (open && taskData._id) {
      dispatch(getAllSubTask({ taskId: taskData._id }))
      dispatch(findCommentsByTaskId({ id: taskData._id }))
    }
  }, [open, taskData._id])

  useEffect(() => {
    if (taskState.assignedAgencyTeam) {
      setCollabOptions(
        getCollaboratorsOptions(
          [...taskState.assignedAgencyTeam, ...taskState.assignedClientTeam],
          workspace
        )
      )
    }
  }, [taskState.assignedAgencyTeam, taskState.assignedClientTeam, workspace])

  // useEffect(() => {
  //   setCollabOptions(getCollaboratorsOptions(taskState))
  // }, [taskState])

  useEffect(() => {
    if (taskState._id) {
      let tags = taskTags.filter(tag => tag.taskIds.includes(taskState._id))
      setAssignedTags(tags)
    }
  }, [taskState, taskTags])

  // useEffect(() => {
  //   setBoardArea(url.includes('/board'))
  // }, [url])

  useEffect(() => {
    if (type !== 'overview' && !inDashboardArea) {
      const subtaskToOpen = allSubtasks.find(item => item._id === subtaskId)
      if (!subtaskId) setOpenSubTask(prev => ({ ...prev, open: false }))
      if (subtaskToOpen) setOpenSubTask({ open: true, data: subtaskToOpen })
    }
  }, [subtaskId, allSubtasks, type])

  // useEffect(() => {
  //   if (taskData._id && approvalPanel.open) {
  //     setApprovalPanel(prev => ({ ...prev, loading: true }))
  //     fetchTaskApprovals({ taskId: taskData._id, type: 'task' }, (res, err) => {
  //       if (!err) {
  //         setApprovalPanel(prev => ({ ...prev, loading: false, data: res }))
  //       } else {
  //         setApprovalPanel(prev => ({ ...prev, loading: false }))
  //         dispatch(fireErrorToaster(res))
  //       }
  //     })
  //   }
  // }, [taskData._id, approvalPanel.open])

  useEffect(() => {
    if (hash) scrollToBottom('auto')
  }, [hash])

  // const savingChanges = () => {
  //   setSavingStatus("saving")
  // }

  const updateTaskData = value => {
    setTaskState(value)
  }

  const taskUpdateCallback = (res, err, options = {}) => {
    if (err) {
      setSavingStatus('failed')
      dispatch(fireErrorToaster(res))
      if (options.resetProp) {
        setTaskState(prev => ({
          ...prev,
          [options.resetProp]: taskData[options.resetProp]
        }))
      }
    } else {
      setSavingStatus('saved')
    }
  }

  const handleDateChange = (e, prop) => {
    if (!canUpdateTask(innerProjectPermission.update)) return
    if (
      ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role) &&
      workspace.restrictDueDate?.isDueDateRestrict &&
      workspace.restrictDueDate?.totalDueDateChangeCounter <=
        taskData.editDueDateCounter
    ) {
      dispatch(
        fireErrorToaster(
          t('ERROR_DUE_DATE_EDITTING_HAS_BEEN_RESTRICTED_BY_ADMIN')
        )
      )
      return
    }

    const date = e ? e.toISOString() : null
    // const savedDate = taskData[prop] //could be start or due date

    setTaskState(prev => ({ ...prev, [prop]: date }))
    setSavingStatus('saving')
    handleTaskDateChange({
      updateKey: prop,
      updateValue: date,
      task: taskState,
      cb: (res, err) => {
        taskUpdateCallback(res, err, { resetProp: prop })
        if (!err) {
          setTaskState(res)
          if (type === 'overview') {
            updateProjectStats(res)
          }
        }
      }
    })
  }

  const handleFileUpload = (file, callback) => {
    const fileName = checkAndGetNewFileName(file, taskData.imageUrl)
    const newFile = new File([file], fileName, {
      type: file.type,
      lastModified: file.lastModified
    })

    setLoadingAttachment(true)
    uploadTaskAttachment({
      taskId: taskData._id,
      workspaceId: taskData.workSpace,
      file: newFile,
      cb: (res, err) => {
        setLoadingAttachment(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setTaskState(prev => ({
            ...prev,
            imageUrl: res.data.imageUrl
          }))

          callback(res.link)
          if (type === 'overview') {
            updateProjectStats(res)
          }
        }
      }
    })
  }

  const handleAttachmentUpload = file => {
    handleFileUpload(file, source => {
      const fileType = file.type.split('/')[0]
      taskDescriptionRef.current.handleInsertEmbed({
        source,
        fileType
      })
    })
  }

  const handleUploadDescriptionAttachment = (file, cb) => {
    handleFileUpload(file, source => {
      cb(source)
    })
  }

  const updateTitle = title => {
    setTaskState(prev => ({ ...prev, title }))

    setSavingStatus('saving')
    dispatch(
      updateTaskDetails(
        {
          taskId: taskState._id,
          data: {
            title,
            field: 'title',
            workSpace: taskState.workSpace,
            taskSection: taskState.taskSection._id,
            socketId
          }
        },
        (res, err) => {
          taskUpdateCallback(res, err)
          if (!err) {
            setTaskState(prev => ({
              ...prev,
              title: res.title
            }))
            if (type === 'overview') {
              updateProjectStats(res)
            }
          }
        }
      )
    )
  }

  const updateDescription = description => {
    setSavingStatus('saving')
    dispatch(
      updateTaskDetails(
        {
          taskId: taskState._id,
          data: {
            description,
            field: 'description',
            workSpace: taskState.workSpace,
            taskSection: taskState.taskSection._id,
            updateType: 'description',
            socketId
          }
        },
        updateTaskDetailsCallback
      )
    )
  }

  const changeEstimateTime = useCallback(
    data => {
      dispatch(
        updateTaskDetails(
          {
            taskId: taskState._id,
            data: {
              ...data,
              field: 'estimateTimeInString',
              workSpace: taskState.workSpace,
              taskSection: taskState.taskSection._id,
              socketId
            }
          },
          (res, err) => {
            if (!err) {
              setTaskState(res)
              if (type === 'overview') {
                updateProjectStats(res)
              }
            }
          }
        )
      )
    },
    [taskState._id, taskState.workSpace, taskState.taskSection, socketId]
  )

  const updateTaskDetailsCallback = (res, err) => {
    taskUpdateCallback(res, err)

    if (!err) {
      if (type === 'overview') {
        updateProjectStats(res)
      }
    }
  }

  const handleTriggerDeleteAttachment = fileUrl => {
    let arr = fileUrl.split('/'),
      fileName = arr[arr.length - 1]
    setAlertModal({
      type: 'attachmentDelete',
      loading: false,
      data: { fileName, fileUrl }
    })
    // handleAttachmentDropDown()
  }

  const handleDeleteAttachment = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteAttachment(
        { taskId: taskData._id, url: alertModal.data.fileUrl },
        deleteAttachmentCallback
      )
    )
  }

  const deleteAttachmentCallback = (res, err) => {
    if (!err) {
      setAlertModal(prev => ({ ...prev, type: '' }))
      setTaskState(prev => ({
        ...prev,
        imageUrl: res.imageUrl
      }))
      if (type === 'overview') {
        updateProjectStats(res)
      }
    }
  }

  const triggerPopover = useCallback((event, value) => {
    setAnchorEl(event.currentTarget)
    setPopperOpen(value)
  }, [])

  const handlePopperClose = () => {
    setPopperOpen('')
    setAnchorEl(null)
  }

  const handleSubTaskClick = useCallback(
    sub => {
      if (openSubTask.open) {
        if (type === 'overview' || isOutsideBoardArea) {
          setOpenSubTask(prev => ({ ...prev, open: false, data: '' }))
        } else {
          history.push(url.split('/task')[0] + '/task/' + taskData._id + search)
        }
      } else {
        if (type === 'overview' || isOutsideBoardArea) {
          setOpenSubTask(prev => ({ ...prev, open: true, data: sub }))
        } else {
          history.push(`${url}/${sub._id}${search}`)
        }
      }
    },
    [openSubTask.open, taskData._id, url]
  )

  const deleteTaskByIDCallback = (res, err) => {
    setAlertModal(prev => ({ ...prev, type: '' }))
    // setOpenDeleteTaskConfirm(false)
    onClose()
    if (err) {
      dispatch(fireErrorToaster(err))
    } else {
      dispatch(fireSuccessToaster(t('DELETED_SUCCESSFULLY')))
    }
  }

  const handleDeleteTask = event => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(moveTaskToTrash({ taskId: taskState._id }, deleteTaskByIDCallback))
  }

  const handleOpenDeleteTaskConfirm = useCallback(e => {
    e.stopPropagation()
    setAlertModal(prev => ({ ...prev, type: 'taskDelete', loading: false }))
  }, [])

  const handleCloseAlert = () => {
    setAlertModal(prev => ({ ...prev, type: '' }))
    setAnchorEl(null)
  }

  const handleAddCollaborator = ({ userRole, userId }) => {
    handlePopperClose()

    setSavingStatus('saving')
    addTaskCollaborator({
      userRole: userRole,
      userId: userId,
      taskId: taskState._id,
      cb: (res, err) => {
        taskUpdateCallback(res, err)
        setTaskState(res)
        if (type === 'overview') {
          updateProjectStats(res)
        }
      }
    })
  }

  const handleRemoveCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')
    removeTaskCollaborator({
      userRole,
      userId,
      taskId: taskState._id,
      cb: (res, err) => {
        taskUpdateCallback(res, err)
        setTaskState(res)
        if (type === 'overview') {
          updateProjectStats(res)
        }
      }
    })
  }

  const toggleApprovalPanel = () => {
    dispatch(
      openApprovalModal({
        module: 'task',
        moduleId: taskState._id,
        approvalItemName: taskState.title,
        approvers: workspace.assignedAgencyTeam
          .concat(workspace.assignedClientTeam)
          .map(item => item.user)
      })
    )
    // setApprovalPanel(prev => ({ ...prev, open: !prev.open }))
  }

  // const addNewApproval = data => {
  //   setApprovalPanel(prev => ({ ...prev, data: [data, ...prev.data] }))
  // }

  // const removeApprovalById = approvalId => {
  //   setApprovalPanel(prev => ({
  //     ...prev,
  //     data: [...prev.data.filter(item => item._id !== approvalId)]
  //   }))
  // }

  // const updateApproval = updatedData => {
  //   setApprovalPanel(prev => ({
  //     ...prev,
  //     data: prev.data.map(item =>
  //       item._id === updatedData._id ? updatedData : item
  //     )
  //   }))
  // }

  const scrollToBottom = (behavior = 'smooth') => {
    // this function gets invoked after someone submits a comment
    logsBottomRef.current.scrollIntoView({ behavior })
    if (selectedTab !== 0) setSelectedTab(0)
  }

  const handleScroll = e => {
    if (e.target.scrollTop > 10) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const updateTaskState = taskData => {
    setTaskState(taskData)
  }

  return (
    <>
      <TaskPanelHeader
        onClose={onClose}
        task={taskState}
        handleAttachmentUpload={handleAttachmentUpload}
        handleOpenDeleteTaskConfirm={handleOpenDeleteTaskConfirm}
        innerProjectPermission={innerProjectPermission}
        triggerPopover={triggerPopover}
        elmFor="task"
        changeEstimateTime={changeEstimateTime}
        canUpdateTask={canUpdateTask}
        currentBoard={boardData}
        inDashboardArea={inDashboardArea}
        openApprovalPanel={toggleApprovalPanel}
        updateTaskData={updateTaskData}
        currency={workspace.currency}
        workspace={workspace}
        socketId={socketId}
        scrollToBottom={scrollToBottom}
      />
      {/* <DrawerContent open={open} style={{ padding: 0 }} onScroll={handleScroll}> */}
      <div
        className="px-8 flex-grow overflow-y-auto"
        style={{ padding: 0 }}
        onScroll={handleScroll}
      >
        <div
          className={clsx(
            'px-2 mt-2 mb-4 flex items-center sticky top-0 z-10 bg-white  dark:bg-dark-main3',
            scrolled && 'border-b'
          )}
        >
          <div className="flex-1">
            <TaskTitle
              innerProjectPermission={innerProjectPermission}
              taskTitle={taskState.title}
              updateTitle={updateTitle}
            />
          </div>

          <div className="text-right px-8">
            <AutoSavingIndicator status={savingStatus} />
          </div>
        </div>

        <div>
          <TaskBody
            taskState={taskState}
            triggerPopover={triggerPopover}
            innerProjectPermission={innerProjectPermission}
            handleDateChange={handleDateChange}
            assignedTags={assignedTags}
            setTagsPopoverData={setTagsPopoverData}
            elmFor="task"
            updateProjectStats={updateProjectStats}
            addCollaborator={handleAddCollaborator}
            removeCollaborator={handleRemoveCollaborator}
            type={type}
            workspace={workspace}
            displayProjectField={displayProjectField}
          />

          <div className="px-6 py-2">
            <TaskDescription
              taskId={taskState._id}
              innerProjectPermission={innerProjectPermission}
              taskDescription={taskState.description}
              updateDescription={updateDescription}
              collaborators={taskState.collaborators}
              handleAttachmentUpload={handleUploadDescriptionAttachment}
              ref={taskDescriptionRef}
              workspace={workspace}
              elmFor="task"
            />
          </div>

          <Subtasks
            workspaceId={taskState.workSpace}
            boardId={taskState.workspaceBoard}
            taskId={taskState._id}
            // taskStatuses={taskState.statuses}
            taskTitle={taskState.title}
            onSubtaskClick={handleSubTaskClick}
            innerProjectPermission={innerProjectPermission}
            socketId={socketId}
            currentWorkspace={workspace}
            currentBoard={boardData}
          />

          {/* -----Attachments ---- */}
          <div className="px-4 flex items-start flex-wrap mx-0 my-4 sm:px-6">
            {taskState?.imageUrl?.length > 0 &&
              taskState?.imageUrl?.map((item, index, arr) => (
                <AttachmentImage
                  key={item._id}
                  fileUrl={item}
                  allFileUrls={arr}
                  fileIndex={index}
                  handleDeleteAttachment={handleTriggerDeleteAttachment}
                />
              ))}

            {innerProjectPermission.update && (
              <AddAttachment
                labelProps={{
                  className: classes.attachmentAddbtn
                }}
                handleFile={handleAttachmentUpload}
                id="task-attachment" //don't remove this id
                disabled={loadingAttachment}
              >
                {loadingAttachment ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Add />
                )}
              </AddAttachment>
            )}
          </div>

          <div>
            {taskState._id && (
              <LogsSection
                task={taskState}
                // logsData={logsData}
                elmFor="task"
                selectedTab={selectedTab}
                workspace={workspace}
                setSelectedTab={setSelectedTab}
              />
            )}
            <span ref={logsBottomRef}></span>
          </div>
        </div>
      </div>
      {/* </DrawerContent> */}
      {/* ========== FOOTER ============= */}
      {!openSubTask.open && taskState._id && (
        <TaskPanelFooter
          taskId={taskState._id}
          elmFor="task"
          setSelectedTab={setSelectedTab}
          workspace={workspace}
          boardId={taskState.workspaceBoard}
          socketId={socketId}
          uploadTaskAttachment={uploadTaskAttachment}
          scrollToBottom={scrollToBottom}
          checkAndGetNewFileName={checkAndGetNewFileName}
          imageUrls={taskData.imageUrl}
          innerProjectPermission={innerProjectPermission}
          collaborators={taskState.collaborators}
          getCollaboratorsOptions={getCollaboratorsOptions}
          updateTaskState={updateTaskState}
        />
      )}
      <SubtaskPanel
        open={openSubTask.open}
        onClose={handleSubTaskClick}
        taskId={openSubTask.data._id}
        // handlePreviewImage={handlePreviewImage}
        innerProjectPermission={innerProjectPermission}
        inDashboardArea={inDashboardArea}
        // groupBy={groupBy}
        // currentWorkspace={currentWorkspace}
        currentBoard={boardData}
        workspace={workspace}
      />

      <DeleteModal
        warningText={t('TASK_DELETE_ALERT_MESSAGE', {
          data: `"${taskState.title}"`
        })}
        handleDialog={handleCloseAlert}
        open={alertModal.type === 'taskDelete'}
        handleDeleteAction={handleDeleteTask}
        archiveButton={false}
        loading={alertModal.loading}
        style={{ zIndex: 2000 }}
      />
      <DeleteModal
        warningText={t('WARNING_THE_ATTACHMENT_NAMED_WILL_BE_DELETED_FOREVER', {
          data: alertModal.data.fileName
        })}
        handleDialog={handleCloseAlert}
        open={alertModal.type === 'attachmentDelete'}
        handleDeleteAction={handleDeleteAttachment}
        loading={alertModal.loading}
        style={{ zIndex: 2000 }}
      />
      <Tags
        taskId={taskState?._id}
        workspace={workspace}
        tagsPopoverData={tagsPopoverData}
        setTagsPopoverData={setTagsPopoverData}
        elmFor="task"
      />
      <CollabDrowdown
        open={popperOpen === 'collab' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        list={collabOptions}
        handleListItemClick={team =>
          handleAddCollaborator({
            userId: team.user._id,
            userRole: team.role
          })
        }
        style={{ zIndex: 2000 }}
        getUser={listItem => ({ ...listItem.user, role: listItem.role })}
      />
    </>
  )
}

export const RowContainer = props => {
  const { children, ...restProps } = props
  const classes = taskSidePanelStyles()
  return (
    <div
      {...restProps}
      className={clsx(
        'px-6',
        classes.rowContainer
        // sm:grid sm:grid-cols-3 sm:gap-4
      )}
    >
      {children}
    </div>
  )
}

export const FieldLabel = props => {
  const { className, children, ...restProps } = props
  return (
    <label
      {...restProps}
      className={clsx(
        'block text-xs text-gray-600 dark:text-dark-light',
        className
      )}
    >
      {children}
    </label>
  )
}
