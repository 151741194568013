import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import userRoles from 'utils/userRoles'
import { ReactComponent as IndentDecrease } from 'static/svg/indent-decrease.svg'
import { ReactComponent as IndentIncrease } from 'static/svg/indent-increase.svg'
import { ReactComponent as CommentsIcon } from 'static/svg/comments.svg'
import {
  openUserFeedbackPopper,
  toggleSidebar
} from 'thunks/generlAppState/actions'
import SidebarCustomTooltip from './SidebarCustomTooltip'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const SidebarFooter = ({ isDrawerOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)

  const handleDrawer = () => {
    dispatch(toggleSidebar())
  }

  return (
    <div
      className={clsx(
        'mt-auto flex justify-between px-4 border-t pt-2 border-gray-300 dark:border-dark-main3',
        isDrawerOpen ? 'flex-row' : 'flex-col'
      )}
    >
      {(meData.role === userRoles.USER_AGENCY ||
        meData.role === userRoles.AGENCY_ADMIN) && (
        <div className={clsx(!isDrawerOpen && 'my-1')}>
          <SidebarCustomTooltip title="Feedback">
            <button
              id="sidebarFooterFeedbackBtn"
              onClick={() => dispatch(openUserFeedbackPopper())}
              className="text-gray-700 dark:text-dark-light hover:text-primary-main"
            >
              <CommentsIcon className="w-5 h-5" />
            </button>
          </SidebarCustomTooltip>
        </div>
      )}

      <div className={clsx(!isDrawerOpen && 'my-1')}>
        <SidebarCustomTooltip
          title={isDrawerOpen ? t('COLLAPSE_SIDEBAR') : t('EXPAND_SIDEBAR')}
        >
          <button onClick={handleDrawer} id="sidebarFooterToggleBtn">
            {isDrawerOpen ? (
              <IndentDecrease className="cursor-pointer hover:text-primary-main rounded-md dark:text-dark-light" />
            ) : (
              <IndentIncrease className="cursor-pointer hover:text-primary-main rounded-md dark:text-dark-light" />
            )}
          </button>
        </SidebarCustomTooltip>
      </div>
    </div>
  )
}

export default SidebarFooter
