import React from 'react'
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import CustomPopper from '../CustomPopper/CustomPopper'

const PopupMenuList = ({ anchorEl, menuItems, onClose }) => {
  const classes = useStyles()

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <List className={classes.list}>
        {menuItems.map((item, index) => (
          <SingleListItem
            key={index}
            item={item}
            handleCloseMenu={onClose}
            classes={classes}
          />
        ))}
      </List>
    </CustomPopper>
  )
}

const SingleListItem = ({ item, handleCloseMenu, classes }) => {
  const handleClick = e => {
    handleCloseMenu(e)
    item.method(item.value, e)
  }

  return (
    <ListItem onClick={handleClick} button>
      <div className="flex items-center py-1">
        {item.icon && (
          <span
            className={clsx(
              'mr-2 dark:text-dark-light2',
              item.isDanger ? 'text-red-400' : 'text-primary-main'
            )}
          >
            {item.icon}
          </span>
        )}
        <ListItemText
          primary={
            <p
              className={clsx(
                'capitalize text-xs 2xl:text-sm dark:text-dark-light2',
                item.isDanger ? 'text-red-400' : 'text-primary-main'
              )}
            >
              {item.label}
            </p>
          }
          classes={{ root: classes.listItemText }}
        />
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles({
  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
})

export default React.memo(PopupMenuList)
