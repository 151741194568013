import { useState, useEffect, useMemo } from 'react'
import { Button, TextField, Tabs, Tab, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LoadingButton } from 'global/globalComponents'
import { whitelabel } from 'thunks/whiteLabel/action'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import invoiceStyles from 'components/Invoices/InvoiceStyles'
import { ME } from 'thunks/me/reducers'
import { userRoles } from 'utils'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

const AdvanceGeneralSettings = ({
  open,
  handleClose,
  updateTaxDetail,
  updateBankDetail
}) => {
  const { t } = useTranslation()
  const classes = invoiceStyles()
  const theme = useTheme()
  const [bankingDetails, setBankingDetails] = useState([])
  const [taxDetails, setTaxDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [filterState, setFilterState] = useState(0)
  const me = useSelector(state => state.me.data)
  const [selectedTab, setSelectedTab] = useState(0)
  const tabButton = [t('BANKING_DETAILS'), t('TAX_DETAILS')]
  let agencyOwner = useMemo(() => {
    if (me.role === userRoles.USER_AGENCY) {
      return me._id
    } else {
      return me.team?._id ?? me.team
    }
  }, [me._id])

  const isSame = useMemo(() => {
    const bankDetail =
      me.profile?.bankDetail ?? me.team?.profile?.bankDetail ?? []
    const taxDetail = me.profile?.taxDetail ?? me.team?.profile?.taxDetail ?? []
    if (filterState === 0) {
      return isEqual(bankDetail, bankingDetails)
    } else {
      return isEqual(taxDetail, taxDetails)
    }
  }, [filterState, bankingDetails, taxDetails, me])

  useEffect(() => {
    if (open && me._id) {
      setBankingDetails(
        me.profile?.bankDetail ?? me.team?.profile?.bankDetail ?? []
      )
      setTaxDetails(me.profile?.taxDetail ?? me.team?.profile?.taxDetail ?? [])
    }
  }, [me, filterState, open])

  const handleAddField = e => {
    e.preventDefault()
    if (filterState === 0) {
      if (bankingDetails.length >= 5)
        return dispatch(
          fireErrorToaster(t('ERROR_MAXIMUM_FIVE_FIELDS_CAN_BE_ADDED'))
        )
      const form = e.currentTarget
      const fieldName = form.fieldName.value.trim()
      const fieldValue = form.fieldValue.value.trim()

      if (fieldName || fieldValue) {
        setBankingDetails(prev => [
          ...prev,
          { label: fieldName, value: fieldValue }
        ])
        form.reset()
        form.fieldName.focus()
      }
    } else {
      if (taxDetails.length >= 5)
        return dispatch(
          fireErrorToaster(t('ERROR_MAXIMUM_FIVE_FIELDS_CAN_BE_ADDED'))
        )
      const form = e.currentTarget
      const fieldName = form.fieldName.value.trim()
      const fieldValue = form.fieldValue.value.trim()

      if (fieldName || fieldValue) {
        setTaxDetails(prev => [
          ...prev,
          { label: fieldName, value: fieldValue }
        ])
        form.reset()
        form.fieldName.focus()
      }
    }
  }

  const handleRemoveField = e => {
    const index = Number(e.currentTarget.dataset.index)
    if (filterState === 0) {
      setBankingDetails(prev => prev.filter((item, idx) => idx !== index))
    } else {
      setTaxDetails(prev => prev.filter((item, idx) => idx !== index))
    }
  }

  const handleSave = () => {
    setLoading(true)
    let data = {}
    if (filterState === 0) {
      data = {
        bankDetail: bankingDetails
      }
    } else {
      data = {
        taxDetail: taxDetails
      }
    }
    dispatch(whitelabel({ id: agencyOwner, body: data }, handleSaveCallback))
  }

  const handleSaveCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster(t('SUCCESS_UPDATED')))
      const updatedMeData = { ...me }
      if (me.role === userRoles.USER_AGENCY) {
        updatedMeData.profile = {
          ...updatedMeData.profile,
          taxDetail: res.taxDetail,
          bankDetail: res.bankDetail
        }
      } else {
        updatedMeData.team.profile = {
          ...updatedMeData.team.profile,
          taxDetail: res.taxDetail,
          bankDetail: res.bankDetail
        }
      }
      dispatch({ type: ME.UPDATE, payload: updatedMeData })
      if (updateTaxDetail) {
        updateTaxDetail(
          res.taxDetail.map(item => `${item.label} - ${item.value}`).join('\n')
        )
      }
      if (updateBankDetail) {
        updateBankDetail(
          res.bankDetail.map(item => `${item.label} - ${item.value}`).join('\n')
        )
      }
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    setFilterState(newVal)
  }

  const handleModalClose = () => {
    setTaxDetails([])
    setBankingDetails([])
    handleClose()
  }

  return (
    <CustomModal open={open} handleClose={handleModalClose}>
      <CustomModalHeader
        heading={t('ADVANCE_DETAIL')}
        handleClose={handleModalClose}
      />

      <CustomModalBody>
        <div className="flex flex-col h-full">
          <div className="mb-6">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {[t('BANKING_DETAILS'), t('TAX_DETAILS')].map((item, index) => (
                <Tab label={item} key={index} />
              ))}
            </Tabs>
          </div>
          <div>
            <div className="mb-2">
              <span className="font-medium text-md">
                {t('ADD_YOUR')} {tabButton[filterState]}
              </span>{' '}
              <span className="text-xs">{t('MAXIMUM_5')}</span>
            </div>

            <div>
              <form onSubmit={handleAddField} className="mb-4">
                <div className="flex flex-wrap gap-2 mt-2">
                  <TextField
                    type="text"
                    placeholder={t('FIELD')}
                    className="border rounded border-custom-gray-main px-2 mr-4"
                    name="fieldName"
                    required
                    style={{
                      background:
                        theme.palette.type !== 'light'
                          ? theme.custom.darkMode.background
                          : ''
                    }}
                  />
                  <TextField
                    type="text"
                    placeholder={t('VALUE')}
                    className="border rounded border-custom-gray-main px-2 sm:flex-1 mr-4"
                    name="fieldValue"
                    required
                    style={{
                      background:
                        theme.palette.type !== 'light'
                          ? theme.custom.darkMode.background
                          : ''
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={
                      (filterState === 0 ? bankingDetails : taxDetails)
                        .length >= 5
                    }
                  >
                    {t('ADD')}
                  </Button>
                </div>
              </form>

              <div className={classes.fieldsWrapper}>
                {(filterState === 0 ? bankingDetails : taxDetails).map(
                  (item, index) => (
                    <div
                      key={index}
                      className="flex mb-4 gap-4 text-smaller font-normal"
                    >
                      <p className=" border rounded shadow px-2 py-1 w-1/4 mr-4 truncate">
                        {item.label}
                      </p>
                      <p className="border rounded shadow px-2 py-1 w-3/4 truncate">
                        {item.value}
                      </p>

                      <button
                        onClick={handleRemoveField}
                        data-index={index}
                        className="ml-2 text-primary-main"
                      >
                        <CloseIcon style={{ height: '16px', width: '16px' }} />
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={handleModalClose}>
          {isSame ? t('CLOSE') : t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          loading={loading}
          variant="contained"
          disabled={isSame}
          onClick={handleSave}
        >
          {t('SAVE')}
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default AdvanceGeneralSettings
