import React from 'react'
import { Tooltip } from '@material-ui/core'
import sidebarStyles from './sidebarStyles'

const SidebarCustomTooltip = props => {
  const classes = sidebarStyles()
  return (
    <Tooltip
      placement="right"
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      {...props}
    />
  )
}

export default SidebarCustomTooltip
