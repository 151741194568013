import React, { useEffect } from 'react'
import { makeStyles, createStyles, Toolbar, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { showHeaderShadow } from 'thunks/generlAppState/actions'
import { useDispatch, useSelector } from 'react-redux'
import { hideChatMessenger } from 'utils'

const CustomDrawer = ({ children, open, styles, ...rest }) => {
  const { payNowStripe } = useSelector(state => state.generalAppState)
  const classes = useStyles({
    styles,
    isPayNowStripe: payNowStripe.isVisible
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(showHeaderShadow(!open))
  }, [open])

  return (
    <Drawer
      open={open}
      classes={{ root: classes.root, paper: classes.drawerPaper }}
      BackdropProps={{
        classes: { root: classes.backdropRoot }
      }}
      transitionDuration={400}
      {...rest}
    >
      <CustomDrawerContentWrapper>{children}</CustomDrawerContentWrapper>
    </Drawer>
  )
}

const CustomDrawerContentWrapper = ({ children }) => {
  useEffect(() => {
    hideChatMessenger()
  }, [])

  return <>{children}</>
}

export default CustomDrawer

export const DrawerHeader = props => {
  const { onClose, children, ...restProps } = props
  const classes = useStyles()
  return (
    <Toolbar
      {...restProps}
      classes={{ root: classes.toolbarRoot, gutters: classes.toolbarGutters }}
    >
      <div className="flex justify-between items-center w-full ">
        <h3 className="text-primary-dark text-xl font-bold dark:text-dark-light">
          {children}
        </h3>
        <button
          onClick={onClose}
          className="w-8 h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
        >
          <CloseIcon className="text-primary-main w-5 h-5 dark:text-dark-light" />
        </button>
      </div>
    </Toolbar>
  )
}

export const DrawerContent = props => {
  const { className, open = false, children, ...restProps } = props

  useEffect(() => {
    if (open) {
      hideChatMessenger()
    }
  }, [open])

  return (
    <div
      {...restProps}
      className={clsx('px-8 flex-grow overflow-y-auto', className)}
    >
      {children}
    </div>
  )
}

export const DrawerFooter = props => {
  const { className, children, ...restProps } = props
  const classes = useStyles()
  return (
    <div
      {...restProps}
      className={clsx(classes.saveBtnContainer, className, 'dark:bg-dark-main')}
    >
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: ({ styles = {} }) => ({
      zIndex: '999 !important',
      ...styles.root
    }),
    backdropRoot: {
      backgroundColor: 'rgba(0, 0, 0, 0.33)'
      // backdropFilter: 'blur(1px)' (makes slider laggy)
    },
    drawerPaper: ({ styles = {}, isPayNowStripe }) => ({
      width: '70vw',
      maxWidth: '600px',
      marginTop: isPayNowStripe
        ? theme.custom.header.height + theme.custom.payStripe.height
        : theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      ...styles.drawerPaper,

      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    }),
    toolbarRoot: {
      backgroundColor: '',
      height: '40px',
      width: '100%',
      minHeight: '50px',
      marginTop: 16,
      zIndex: theme.zIndex.appBar
    },
    toolbarGutters: {
      padding: '0px 30px'
    },
    saveBtnContainer: {
      width: '100%',
      flexGrow: 0,
      flexShrink: 0,
      // bottom: '0',
      zIndex: '2',
      backgroundColor: '#fff',
      padding: theme.spacing(1.75, 4),
      // marginLeft: theme.spacing(-2),
      borderTop: '1px solid #c6c6c6',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2)
    }
  })
)
