import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import CloseIcon from '@material-ui/icons/Close'
// import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
// import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import { CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const ToastIcon = props => {
  return (
    <CircularProgress
      style={{ width: '16px', height: '16px', color: 'green' }}
    />
  )
}

const Toast = props => {
  const { t } = useTranslation()
  const severityParts = {
    error: {
      text: t('TRY_AGAIN'),
      color: 'text-red-400',
      backgroundColor: 'bg-red-50 dark:bg-dark-main2',
      borderColor: 'bg-red-400'
    },
    success: {
      text: t('SUCCESS'),
      color: 'text-green-600',
      backgroundColor: 'bg-green-50 dark:bg-dark-main2',
      borderColor: 'bg-green-600'
    },
    process: {
      text: t('PROCESSING'),
      color: 'text-green-600',
      backgroundColor: 'bg-green-50 dark:bg-dark-main2',
      borderColor: 'bg-green-600'
    },
    warning: {
      text: t('WARNING'),
      color: 'text-yellow-500',
      backgroundColor: 'bg-yellow-50 dark:bg-dark-main2',
      borderColor: 'bg-yellow-400'
    },
    info: {
      text: t('DID_YOU_KNOW'),
      color: 'text-primary-main',
      backgroundColor: 'bg-blue-50 dark:bg-dark-main2',
      borderColor: 'bg-primary-main'
    }
  }
  const { open, message, severity, closeHandler } = props

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end justify-start px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start"
      style={{ zIndex: 99999 }}
      id="toastWrapper"
    >
      <Transition
        show={open}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx(
            'max-w-sm w-full bg-white shadow-lg rounded-2xl pointer-events-auto overflow-hidden py-4',
            severityParts[severity]?.backgroundColor
          )}
        >
          <div className="px-4 border-current relative">
            <span
              className={clsx(
                'inline-block absolute top-0 left-0 bottom-0 w-1 rounded-r-md',
                severityParts[severity]?.borderColor
              )}
            ></span>
            <div className="flex items-start">
              <div className="ml-1 w-0 flex-1 pt-0.5">
                {severity !== 'process' ? (
                  <p
                    className={clsx(
                      'text-sm font-bold text-inherit',
                      severityParts[severity]?.color
                    )}
                  >
                    {severityParts[severity]?.text ?? ''}
                  </p>
                ) : (
                  <div className="flex-shrink-0">
                    <ToastIcon
                      className={`h-6 w-6 ${
                        severityParts[severity]?.color ?? ''
                      }`}
                      aria-hidden="true"
                      severity={severity}
                    />
                  </div>
                )}
                <p
                  className="text-sm text-gray-500 font-medium"
                  data-status={severity}
                  id="toastMessage"
                >
                  {message}
                </p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className={clsx(
                    'rounded-md inline-flex',
                    severityParts[severity]?.color
                  )}
                  onClick={closeHandler}
                >
                  <CloseIcon fontSize="small" />
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Toast
