import React, { useEffect, useState } from 'react'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { useHistory } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as LogoutIcon } from 'static/svg/logout.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import { LOGIN } from 'thunks/login/reduers'
import { useTranslation } from 'react-i18next'
import socket from 'config/socketConfig'
import { ReactComponent as PulseIcon } from 'static/svg/pulse.svg'
import TimerPopupOptions from './GlobalHeader/TimerPopupOptions'
import { ReactComponent as BriefcaseIcon } from 'static/svg/briefcase.svg'
import ProfileMenuPopup from './ProfileMenuPopup'
import { ReactComponent as LightMode } from 'static/svg/moon.svg'
import { ReactComponent as DarkMode } from 'static/svg/moon_filled.svg'
import { updateUser } from 'thunks/user/actions'
import { updateStateTheme } from 'thunks/generlAppState/actions'

const HeaderProfileMenu = ({
  meData,
  size = 'normal',
  timerData,
  toggleLogModal,
  toggleTimerModal,
  toggleStandupsModal
}) => {
  const { t } = useTranslation()
  const { mode: themeForMode } = useSelector(
    state => state.generalAppState.theme
  )
  const [options, setOptions] = useState([])
  const agencyPreview = useSelector(state => state.login.agencyPreview)
  const timestamp = new Date().getTime()

  const accountRoles = {
    [userRoles.USER_AGENCY]: {
      label: t('SUPER_ADMIN'),
      color: '#4d8dde'
    },
    [userRoles.AGENCY_MANAGER]: {
      label: t('MANAGER'),
      color: '#69d4ff'
    },
    [userRoles.AGENCY_EDITOR]: {
      label: t('COLLABORATOR'),
      color: '#7a78d6'
    },
    [userRoles.AGENCY_ADMIN]: {
      label: t('C-LEVEL'),
      color: '#DB1F48'
    }
  }
  const handleUsersPulse = () => {
    history.push(`${getUserPath(meData.role)}/pulse`)
  }

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const arr = [
      {
        label: t('MY_PROFILE'),
        icon: <UserIcon />,
        method: goToProfile
      },
      {
        label: t('SETTINGS'),
        icon: <SettingIcon />,
        method: goToSettings
      }
    ]

    if (!agencyPreview) {
      arr.push({
        label: t('LOGOUT'),
        icon: <LogoutIcon style={{ transform: 'rotate(-90deg)' }} />,
        method: handleLogout
      })
    }

    if (meData.role < userRoles.USER_CLIENT) {
      arr.splice(
        1,
        0,
        {
          label: t('PULSE'),
          icon: <PulseIcon />,
          method: handleUsersPulse
        },
        {
          label: t('TRASH'),
          icon: <TrashIcon />,
          method: goToTrash
        }
      )

      if (
        meData.role < userRoles.USER_CLIENT &&
        !timerData?.activeTimer &&
        toggleLogModal
      ) {
        arr.splice(1, 0, {
          element: (
            <TimerPopupOptions
              toggleTimerModal={toggleTimerModal}
              toggleLogModal={toggleLogModal}
              // toggleRecording={toggleRecording}
            />
          )
        })
      }

      if (toggleStandupsModal) {
        arr.splice(1, 0, {
          label: t('DAILY_STANDUPS'),
          icon: <BriefcaseIcon />,
          method: toggleStandupsModal
        })
      }
    }

    arr.splice(1, 0, {
      label:
        themeForMode === 'dark'
          ? t('DISABLE_DARK_MODE')
          : t('ENABLE_DARK_MODE'),
      icon: themeForMode === 'dark' ? <LightMode /> : <DarkMode />,
      method: handleModeChange
    })

    setOptions(arr)
  }, [meData.role, timerData, themeForMode, agencyPreview])

  const handleLogout = () => {
    if (socket.connected) socket.disconnect()
    dispatch({ type: LOGIN.LOGOUT })
  }

  const goToSettings = () => {
    history.push(
      `${getUserPath(meData.role)}${
        ![
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_ADMIN
        ].includes(meData.role)
          ? '/settings/profile'
          : '/settings/branding'
      }`
    )
  }

  const goToProfile = () => {
    history.push(`${getUserPath(meData.role)}/settings/profile`)
  }

  const handleModeChange = () => {
    let updatedData = {
      isDarkMode: themeForMode === 'dark' ? false : true
    }
    dispatch(
      updateStateTheme({ mode: updatedData.isDarkMode ? 'dark' : 'light' })
    )
    dispatch(updateUser(updatedData))
  }

  const goToTrash = () => {
    history.push(`${getUserPath(meData.role)}/trash`)
  }

  return (
    <div>
      <div className="flex items-center mr-2 sm:mr-0">
        {accountRoles[meData.role] && (
          <div
            className="py-1 px-2 mr-2 text-tiny 2xl:text-xs text-white rounded-full capitalize"
            style={{ background: accountRoles[meData.role].color }}
          >
            {accountRoles[meData.role].label}
          </div>
        )}

        <ProfileMenuPopup
          targetComp={
            <ColoredAvatars
              user={{
                ...meData,
                profileImage: `${meData.profileImage}?t=${timestamp}` //adding timestamp to prevent caching, it works sort of like refresh
              }}
              size={size}
              tooltip={false}
              className="cursor-pointer"
            />
          }
          menuItems={options}
        />
      </div>
    </div>
  )
}

export default React.memo(HeaderProfileMenu)
